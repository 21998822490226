<template>
  <div>
    <div id="container">

      <section id="register">
        <div class="wrap">
          <form @submit="submitClicked">
            <div class="cts_box">
              <h2 class="sub_ttl"><span>複眼経済塾 新規入塾申し込み</span></h2>

              <div v-html="coupon_lead_text"></div>

              <h3 class="min_ttl mt20">お支払い内容をご確認ください</h3>
              <div class="tax_ttl flex">
                <h4></h4>
                <p class="tax_caution bold red">※表示はすべて税込価格です</p>
              </div>
              <div class="selected_class">
                <div class="class_box flex">
                  <h4>現在選択中のクラス</h4>
                  <div class="class_name">学生科</div>
                  <div class="price_ttl">今月のお支払金額<br><span class="caution">※初月分は日割りでの請求となります</span></div>
                  <div class="price">¥{{ delimited(studentFirstPrice()) }}</div>
                </div>
                <div class="class_box next flex">
                  <div class="price_ttl">来月からのご請求金額</div>
                  <div class="price">¥2,000/月</div>
                </div>
              </div>
            </div>

            <div class="cts_box">
              <h3 class="min_ttl">利用規約をご一読ください</h3>
              <p class="lead_txt">
                利用規約をご一読いただき、これらの条件にご同意の上「利用規約に同意します」にチェックを入れてから「塾生登録を開始する」ボタンを押してください。
              </p>
              <div class="mail_check">
                <form-error-box v-if="errors"/>
                <div class="input_set">
                  <div class="label">複眼経済塾利用規約</div>
                  <div class="term_box">
                    <div class="scrl_box" v-html="term.text"></div>
                  </div>
                </div>
                <div class="input_set">
                  <div class="checkbox alnC">
                    <input id="check01" type="checkbox" v-model="new_user.agreement" value="1"
                           v-on:change="agreementChanged()">
                    <label for="check01" class="bold">複眼経済塾 利用規約に同意します</label>
                  </div>
                </div>
              </div>
              <p class="alnC mt20 mb20"> 上記同意にチェックを入れると塾生登録のボタンが表示されます。 </p>
              <div class="button_wrap">
                <input type="submit" class="submit light" value="塾生登録を開始する"
                       v-bind:disabled="!this.new_user.agreement">
              </div>
            </div>
          </form>
        </div><!--//.wrap-->
      </section>

    </div><!--//＃container-->
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  data() {
    this.$store.state.user = {
      rank: 'rank_student_month',
      agreement: false
    }
    return {
      new_user: this.$store.state.user,
      campaign_code: this.$route.params.campaign_code,
      coupon_lead_text: null,
      term: {},
      errors: null,
    }
  },
  mounted() {
    if (this.campaign_code) {
      this.checkCouponCode()
    }

    this.axios
        .get(`${this.env.api_base_url}term.json`)
        .then(response => {
          this.term = response.data.term
          this.new_user.term_id = this.term.id
        })
        .catch(error => {
          if (error.response.data && error.response.data.errors) {
            console.log(error.response.data);
          }
        });
  },
  created() {
    //
  },
  methods: {
    agreementChanged() {
      this.$forceUpdate();
    },

    checkCouponCode() {
      this.startAjax()
      this.axios
          .post(`${this.env.api_base_url}users/campaign_code.json`, {
            campaign_code: this.campaign_code,
          })
          .then((response) => {
            this.coupon_lead_text = response.data.lead_text
          })
          .catch((error) => {
            if (error.response.data.code && error.response.data.code === 400) {
              this.$router.push({path: '/customer/account/partner/close'})
            } else {
              this.$router.push({name: '404'})
            }
          })
          .finally(() => {
            this.finishAjax()
          })
    },

    submitClicked(event) {
      event.preventDefault()
      this.errors = null
      if (!this.new_user.agreement) {
        this.errors = ['登録には利用規約に同意頂く必要があります。']
      } else {
        if (this.campaign_code) {
          // console.log(this.campaign_code)
          this.new_user.campaign_code = this.campaign_code
        }
        // 遷移
        this.$router.push({name: 'UsersConfirmationStudentPage1'})
      }
    }
  }
}
</script>
